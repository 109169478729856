import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
 
  {
    path: "/about",
    name: "about",
    component: () =>
      import("../views/AboutView.vue"),
  },
  {
    path: "/home",
    name: "homevue",
    component: () => import("../views/home/home.vue"),
  },
  {
    path: "/assessment",
    name: "assessment",
    component: () =>
      import("../views/home/assessment.vue"),
  },
  {
    path: "/password",
    name: "password",
    component: () =>
      import("../views/home/password.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import("../views/home/user.vue"),
  },
  //自由练习
  {
    path: "/practice",
    name: "practice",
    component: () =>
      import("../views/practice/index.vue"),
  },
  // 模拟考试
  {
    path: "/Practice_exams",
    name: "Practice_exams",
    component: () =>
      import("../views/Practice_exams/index.vue"),
  },
  {
    path: "/Practice_exams/choose",
    name: "/Practice_exams/choose",
    component: () =>
    import("../views/Practice_exams/choose.vue"),
  },
  // 在线课程
  {
    path: "/Online_courses",
    name: "/Online_courses",
    component: () =>
      import("../views/Online_courses/index.vue"),
  },
  {
    path: "/Online_courses/online",
    name: "/Online_courses/online",
    component: () =>
      import("../views/Online_courses/online.vue"),
  },
  // 考试历史
  {
    path: "/Exam_history",
    name: "Exam_history",
    component: () =>
      import("../views/Exam_history/index.vue"),
  },
  {
    path: "/Exam_history/practice",
    name: "Exam_history/practice",
    component: () =>
      import("../views/Exam_history/practice.vue"),
  },
  {
    path: "/Exam_history/grades",
    name: "Exam_history/grades",
    component: () =>
      import("../views/Exam_history/grades.vue"),
  },
  {
    path: "/Exam_history/wrong",
    name: "Exam_history/wrong",
    component: () =>
      import("../views/Exam_history/wrong.vue"),
  },
  {
    path: "/Exam_history/note",
    name: "Exam_history/note",
    component: () =>
      import("../views/Exam_history/note.vue"),
  },
  {
    path: "/Exam_history/collection",
    name: "Exam_history/collection",
    component: () =>
      import("../views/Exam_history/collection.vue"),
  },
  // 考场
  {
    path: "/Question_bank",
    name: "Question_bank",
    component: () =>
      import("../views/Question_bank/index.vue"),
  },
  {
    path: "/Question_bank/wrogpractise/:id",
    name: "Question_bank",
    component: () =>
      import("../views/Question_bank/wrongpractise.vue"),
  },

  {
    path: "/Question_bank/practice",
    name: "Question_bank/practice",
    component: () =>
      import("../views/Question_bank/practice.vue"),
  },
  {
    path: "/Question_bank/freely",
    name: "Question_bank/freely",
    component: () =>
      import("../views/Question_bank/freely.vue"),
  },
  {
    path: "/Question_bank/favorites",
    name: "Question_bank/favorites",
    component: () =>
      import("../views/Question_bank/favorites.vue"),
  },
  {
    path: "/Question_bank/score",
    name: "Question_bank/score",
    component: () =>
      import("../views/Question_bank/score.vue"),
  },
  {
    path: "/timer",
    name: "timer",
    component: () =>
      import("../views/timer/index.vue"),
  },
  {
    path: "/practiceRes",
    name: "practiceRes",
    component: () =>
      import("../views/practiceRes/index.vue"),
  },
  {
    path: "/practiceRes/practice",
    name: "practiceRes/practice",
    component: () =>
      import("../views/practiceRes/practice.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import("../views/login/register.vue"),
  },
  {
    path: "/email",
    name: "email",
    component: () =>
      import("../views/login/email.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () =>
      import("../views/login/forgot.vue"),
  },
  {
    path: "/myerrorproblemlist",
    name: "myerrorproblemlist",
    component: () =>
      import("../views/practiceRes/myErrorList.vue"),
  },
  {
    path: "/mycollect",
    name: "mycollect",
    component: () =>
      import("../views/practiceRes/myCollect.vue"),
  },
  {
    path: "/mynote",
    name: "mynote",
    component: () =>
      import("../views/practiceRes/myNote.vue"),
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: 'Page not found',
      isLogin: false
    },
    component: () => import('@/views/404.vue')
  },
  // 所有未定义路由，全部重定向到404页
  {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//路由守卫
router.beforeEach((to, from, next) => {
  var token = window.localStorage.getItem('token')//  从LocalStorage中得到token
  if (!token) {
    if(to.path === '/') {
      return next('/login');
    }
    if (to.path === '/login' || to.path === '/register' ||to.path === '/email' || to.path === '/forgot') {
      return next();
    } else {
      return next('/login');
    }
  } else {
    if(to.path === '/') {
      return next('/home');
    } else {
      next()
    }
   
  }
})

export default router;
